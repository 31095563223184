// eslint-disable-next-line import/named
import { GENERAL_ORDERS_META } from '../../meta/settings/orders'

export default [
  {
    path: 'orders',
    name: 'settings-orders',
    component: () => import('@/views/settings/orders/Orders.vue'),
    redirect: { name: 'settings-orders-general-rules' },
    children: [
      {
        path: 'general-rules',
        name: 'settings-orders-general-rules',
        component: () => import('@/views/settings/orders/general-rules/GeneralRules.vue'),
        layout: 'Orders',
        meta: GENERAL_ORDERS_META,
      },
      {
        path: 'transfer-orders',
        name: 'settings-orders-transfer-orders',
        component: () => import('@/views/settings/orders/transfer-orders/TransferOrders.vue'),
        layout: 'Orders',
        meta: GENERAL_ORDERS_META,
      },
      {
        path: 'credit-notes',
        name: 'settings-orders-credit-notes',
        component: () => import('@/views/settings/orders/credit-notes/CreditNotes.vue'),
        layout: 'Orders',
        meta: GENERAL_ORDERS_META,
      },
      {
        path: 'service-orders',
        name: 'settings-orders-service',
        component: () => import('@/views/settings/orders/service-orders/ServiceOrders.vue'),
        layout: 'Orders',
        meta: GENERAL_ORDERS_META,
      },
      {
        path: 'rental-orders',
        name: 'settings-orders-rental',
        component: () => import('@/views/settings/orders/rental-orders/RentalOrders.vue'),
        layout: 'Orders',
        meta: GENERAL_ORDERS_META,
      },
      {
        path: 'purchase-orders',
        name: 'settings-orders-purchase',
        component: () => import('@/views/settings/orders/purchase-orders/PurchaseOrders.vue'),
        layout: 'Orders',
        meta: GENERAL_ORDERS_META,
      },
    ],
  },
]
